import React from "react"
import PropTypes from "prop-types"
import { Wrapper, ListNumber, Downloads } from "./styles"
import { H3, H2, P } from "../../styles/typography"

const FileDownload = ({ listNumber, title, documents }) => {
  return (
    <Wrapper>
      <ListNumber>
        <H3>{listNumber}</H3>
      </ListNumber>
      <Downloads>
        <H2>{title}</H2>
        {documents &&
          documents.map(({ title, src }, index) => (
            <P size="large" key={index}>
              <a href={src} download>
                {title}
              </a>
            </P>
          ))}
      </Downloads>
    </Wrapper>
  )
}
FileDownload.propTypes = {
  listNumber: PropTypes.string,
  title: PropTypes.string,
  documents: PropTypes.array,
}

FileDownload.defaultProps = {
  listNumber: "",
  title: "",
  documents: null,
}

export default FileDownload
