import * as React from "react"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import FileDownload from "../components/FileDownload/fileDownload"
import { main } from "../styles/theme"
import { P } from "../styles/typography"
import Fasader from "../../static/documents/fasadritningar.pdf"
import Sektioner from "../../static/documents/sektionsritningar.pdf"
import Kostnadskalkyl from "../../static/documents/kostnadskalkyl_brf_langgatans_smedja.pdf"
import Stadgar from "../../static/documents/stadgar-brf-langgatans-smedja.pdf"
import Bofaktablad from "../../static/documents/bofaktablad_smedjan.pdf"
import Materialbeskrivning from "../../static/documents/materialbeskrivning_smedjan.pdf"
import Prospekt from "../../static/documents/smedjan_prospekt_digital.pdf"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 60px;
  }
`
const CustomH1 = styled.h1`
  font-size: 20vw;
  line-height: 0.5;
  letter-spacing: -0.02em;
  font-weight: normal;
  margin: 0;
  white-space: break-spaces;

  @media ${main.breakpoints.small} {
    font-size: 21vw;
    line-height: 0.6;
  }

  @media ${main.breakpoints.xlarge} {
    font-size: 21.5vw;
    line-height: 0.7;
  }
`

const Documents = styled.ul`
  padding: 90px 0 100px;
  margin: 0;
  list-style-type: none;

  > p {
    margin-top: 40px;
  }
`

const Dokument = () => (
  <Layout>
    <Seo title="Dokument" />
    <HeadingWrapper>
      <CustomH1>Dokument</CustomH1>
    </HeadingWrapper>
    <Documents>
      <FileDownload
        listNumber="(00)"
        title="Ritningar"
        documents={[
          {
            title: "Fasader",
            src: Fasader,
          },
          {
            title: "Sektioner",
            src: Sektioner,
          },
          {
            title: "Bofaktablad",
            src: Bofaktablad,
          },
        ]}
      />
      <FileDownload
        listNumber="(01)"
        title="Förening"
        documents={[
          {
            title: "Stadgar",
            src: Stadgar,
          },
          {
            title: "Kostnadskalkyl",
            src: Kostnadskalkyl,
          },
        ]}
      />
      <FileDownload
        listNumber="(02)"
        title="Material"
        documents={[
          {
            title: "Materialbeskrivning",
            src: Materialbeskrivning,
          },
          {
            title: "Prospekt",
            src: Prospekt,
          },
        ]}
      />
      <P size="large">4D Gruppen reserverar sig för eventuella justeringar</P>
    </Documents>
  </Layout>
)

export default Dokument
