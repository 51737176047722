import styled from "styled-components"
import { main } from "../../styles/theme"
import { gridLayout } from "../../styles/mixins"

export const Wrapper = styled.li`
  ${gridLayout}

  padding: 24px 0;
  border-top: 1px solid #b3b3b3;
`

export const ListNumber = styled.div`
  grid-column: 1 / -1;
  margin-bottom: 1rem;

  @media ${main.breakpoints.medium} {
    grid-column: 1 / span 6;
  }
`

export const Downloads = styled.div`
  grid-column: 1 / -1;

  h3 {
    margin: 4px 0 24px;
  }
  @media ${main.breakpoints.medium} {
    grid-column: 7 / span 6;
  }
`
